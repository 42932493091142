import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { math, stripUnit } from 'polished';

import { h1Size, h1SizeMin, h2Size, h2SizeMin, h3Size, h3SizeMin, h4Size, h4SizeMin, h5Size, h5SizeMin, fontSize, fontSizeMin, minTablet } from 'Utils/variables';
import colors from 'Utils/theme';

import { responsiveFont } from './helpers';

export const heading = (props) => css`
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1;

  color: ${props.light ? colors.white : colors.walmart.blue};
`;

export const H1 = styled.h1`
  ${heading}
  ${({ sizes = `${h1SizeMin}, ${h1Size}` }) => responsiveFont({ sizes })};

  line-height: ${40 / stripUnit(h1SizeMin)};
  /* letter-spacing: calc(${math(`-${2 / stripUnit(h1Size)}`)} * 1em); */
  margin-bottom: ${({ marginBottom = 15 }) => `calc(${math(`${stripUnit(marginBottom)} / ${stripUnit(h1Size)}`)} * 1em)`};

  @media (min-width: ${minTablet}) {
    line-height: ${66 / stripUnit(h1Size)};
  }
`;

export const H2 = styled.h2`
  ${heading};
  ${({ sizes = `${h2SizeMin}, ${h2Size}` }) => responsiveFont({ sizes })};

  line-height: ${60 / stripUnit(h2Size)};
  /* letter-spacing: calc(${math(`-${1 / stripUnit(h2Size)}`)} * 1em); */
  margin-bottom: ${({ marginBottom = 15 }) => `calc(${math(`${stripUnit(marginBottom)} / ${stripUnit(h2Size)}`)} * 1em)`};
`;

export const H3 = styled.h3`
  ${heading};
  ${({ sizes = `${h3SizeMin}, ${h3Size}` }) => responsiveFont({ sizes })};

  /* letter-spacing: calc(${math(`-${0.5 / stripUnit(h3Size)}`)} * 1em); */
  line-height: ${48 / stripUnit(h3Size)};
  margin-bottom: ${({ marginBottom = 15 }) => `calc(${math(`${stripUnit(marginBottom)} / ${stripUnit(h3Size)}`)} * 1em)`};
`;

export const H4 = styled.h4`
  ${heading};
  ${({ sizes = `${h4SizeMin}, ${h4Size}` }) => responsiveFont({ sizes })};

  /* letter-spacing: calc(${math(`-${0.4 / stripUnit(h4Size)}`)} * 1em); */
  line-height: ${27 / stripUnit(h4Size)};
  margin-bottom: ${({ marginBottom = 15 }) => `calc(${math(`${stripUnit(marginBottom)} / ${stripUnit(h4Size)}`)} * 1em)`};
`;

export const H5 = styled.h5`
  ${heading};
  ${({ sizes = `${h5SizeMin}, ${h5Size}` }) => responsiveFont({ sizes })};

  /* letter-spacing: calc(${math(`-${0.4 / stripUnit(h5Size)}`)} * 1em); */
  line-height: ${48 / stripUnit(h5Size)};
  margin-bottom: ${({ marginBottom = 15 }) => `calc(${math(`${stripUnit(marginBottom)} / ${stripUnit(h5Size)}`)} * 1em)`};
`;

export const Copy = styled.p`
  ${({ sizes = `${fontSizeMin}, ${fontSize}` }) => responsiveFont({ sizes })};

  color: ${(props) => (props.light ? colors.white : props.color || colors.inHome.darkGrey)};
  line-height: ${({ lineHeight = 30 }) => stripUnit(lineHeight) / stripUnit(fontSizeMin)};

  @media (min-width: ${minTablet}) {
    line-height: ${({ lineHeight = 30 }) => stripUnit(lineHeight) / stripUnit(fontSize)};
  }
`;
